@media all and (max-width: 1019px) {
  .t_dn { display: none; }
  .t_dn_i { display: none !important; }
  .t_db { display: block; }
  .t_dib { display: inline-block; }
  .t_dt { display: table; }
  .t_dtr { display: table-row; }
  .t_dtc { display: table-cell; }

  .t_w10p { width: 10%; }
  .t_w12p { width: 12.5%; }
  .t_w15p { width: 15%; }
  .t_w20p { width: 20%; }
  .t_w25p { width: 25%; }
  .t_w33p { width: 33.3333%; }
  .t_w50p { width: 50%; }
  .t_w65p { width: 65%; }
  .t_w66p { width: 66.6666%; }
  .t_w70p { width: 70%; }
  .t_w75p { width: 75%; }
  .t_wfull { width: 100%; }
  .t_wauto { width: auto; }

  .t_tal { text-align: left; }
  .t_tac { text-align: center; }
  .t_tar { text-align: right; }

  .t_fz8, .t_fz8_a, .t_fz8_a * { font-size: 8px; }
  .t_fz10, .t_fz10_a, .t_fz10_a * { font-size: 10px; }
  .t_fz11, .t_fz11_a, .t_fz11_a * { font-size: 11px; }
  .t_fz12, .t_fz12_a, .t_fz12_a * { font-size: 12px; }
  .t_fz14, .t_fz14_a, .t_fz14_a * { font-size: 14px; }
  .t_fz15 { font-size: 15px; }
  .t_fz16 { font-size: 16px; }
  .t_fz18 { font-size: 18px; }
  .t_fz20 { font-size: 20px; }
  .t_fz22 { font-size: 22px; }
  .t_fz24 { font-size: 24px; }
  .t_fz26 { font-size: 26px; }
  .t_fz28 { font-size: 28px; }
  .t_fz30 { font-size: 30px; }
  .t_fz32 { font-size: 32px; }
  .t_fz34 { font-size: 34px; }
  .t_fz36 { font-size: 36px; }
  .t_fz46 { font-size: 46px; }
  .t_fz48 { font-size: 48px; }
  .t_fz50 { font-size: 50px; }

  .t_fln { float: none; }
  .t_fll { float: left; }
  .t_flr { float: right; }


  .wrapper {
    width: 100%;
  }
    .unwrapper {
      margin-left: -10px;
      margin-right: -10px;
    }


  .gallery-block-container .gallery-item.gallery-item-width-2 { width: 50%; }
  .gallery-block-container .gallery-item.gallery-item-width-3 { width: 33.33%; }
  .gallery-block-container .gallery-item.gallery-item-width-4 { width: 25%; }
  .gallery-block-container .gallery-item.gallery-item-width-5 { width: 20%; }
  .gallery-block-container .gallery-item.gallery-item-width-6 { width: 16.66%; }
    .gallery-block-container .gallery-item img { width: 100%; }


  .header {
    border-left: 50px solid #373a45;
  }
    .header > .wrapper {
      padding-top: 12px;
      padding-bottom: 12px;
    }
      .header-name {
        padding-left: 0px;
        border-left: none;
      }

    .header-menu-container {
      height: 0px;
      z-index: 99;
    }
      .header-menu-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #373a45;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 50px;
        height: 50px;
        padding: 11px;
        cursor: pointer;
        z-index: 99;
      }
        .header-menu-button span {
          display: block;
          position: relative;
        }
          .header-menu-button span,
          .header-menu-button span:before,
          .header-menu-button span:after {
            content: "";
            width: 100%;
            height: 3px;
            background: #fff;

            -webkit-transition: all 0.2s ease 0s;
            -moz-transition: all 0.2s ease 0s;
            -o-transition: all 0.2s ease 0s;
            transition: all 0.2s ease 0s;
          }
          .header-menu-button span:before {
            top: -8px;
            position: absolute;
          }
          .header-menu-button span:after {
            bottom: -8px;
            position: absolute;
          }
          .header-menu-checkbox:checked + .header-menu-button span {
            background: transparent;
          }
          .header-menu-checkbox:checked + .header-menu-button span:before,
          .header-menu-checkbox:checked + .header-menu-button span:after {
            width: 120%;
            margin-left: -10%;
          }
          .header-menu-checkbox:checked + .header-menu-button span:before {
            top: 0px;

            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          .header-menu-checkbox:checked + .header-menu-button span:after {
            bottom: 0px;

            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

      .header-menu-container > .wrapper {
        background: #373a45;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 98;

        visibility: hidden;
        opacity: 0;

        /* эффекты во время СКРЫТИЯ */
        -webkit-transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s, bottom 0s ease 0.2s;
        -moz-transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s, bottom 0s ease 0.2s;
        -o-transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s, bottom 0s ease 0.2s;
        transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s, bottom 0s ease 0.2s;
      }
        .header-menu-checkbox:checked ~ .wrapper {
          bottom: 0%;
          visibility: visible;
          opacity: 1;

          /* эффекты во время ОТКРЫТИЯ */
          -webkit-transition: bottom 0s ease 0s, visibility 0s ease 0s, opacity 0.2s ease 0s;
          -moz-transition: bottom 0s ease 0s, visibility 0s ease 0s, opacity 0.2s ease 0s;
          -o-transition: bottom 0s ease 0s, visibility 0s ease 0s, opacity 0.2s ease 0s;
          transition: bottom 0s ease 0s, visibility 0s ease 0s, opacity 0.2s ease 0s;
        }
        .header-menu {
          flex-direction: column;
          align-items: stretch;

          position: absolute;
          top: 60px;
          left: 10px;
          right: 10px;
          bottom: 10px;
          overflow: scroll;
        }
          .header-menu li {
            margin: 0px !important;
            padding: 0px !important;
          }
            .header-menu li > div:first-child {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              background: transparent;
            }
              .header-menu li > div:first-child > div:first-child {
                width: 100%;
              }
                .header-menu li > div:first-child > div > a,
                .header-menu li > div:first-child > div > span {
                  display: block;
                  color: #fff;
                  font-size: 16px !important;
                  padding: 10px 16px 12px !important;
                  border-bottom: 2px solid rgba(255,255,255,.2) !important;

                  -webkit-transition: color 0.3s ease 0s, border-color 0.3s ease 0s !important;
                  -moz-transition: color 0.3s ease 0s, border-color 0.3s ease 0s !important;
                  -o-transition: color 0.3s ease 0s, border-color 0.3s ease 0s !important;
                  transition: color 0.3s ease 0s, border-color 0.3s ease 0s !important;
                }
                  .header-menu li > div:first-child > div:hover > a,
                  .header-menu li > div:first-child > div:hover > span,
                  .header-menu li > div:first-child > div.is-hovered > a,
                  .header-menu li > div:first-child > div.is-hovered > span,
                  .header-menu li.active > div:first-child > div > a {
                    border-color: #fff !important;
                  }

              .header-menu li > div > .js-menu-dropmarker {
                display: block;
                background: rgba(55,58,69,.4);
                font-size: 0px;
                width: 60px;
                padding: 0px;
                margin: 0px;
                border-bottom: 2px solid rgba(255,255,255,.2);
                cursor: pointer;
                position: relative;

                -webkit-transition: border-color 0.3s ease 0s;
                -moz-transition: border-color 0.3s ease 0s;
                -o-transition: border-color 0.3s ease 0s;
                transition: border-color 0.3s ease 0s;
              }
                .header-menu li > div > .js-menu-dropmarker:hover,
                .header-menu li.is-hovered > div > .js-menu-dropmarker {
                  border-color: #fff;
                }
                .header-menu li > div > .js-menu-dropmarker:before {
                  content: "";
                  display: block;
                  position: absolute;
                  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDQwIDIzIj48dGl0bGU+RG93biBhcnJvdyB3aGl0ZTwvdGl0bGU+PHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1cHgiIGQ9Ik0zNy4wMDAsMy4wMDAgTDIwLjAwMCwyMC4wMDAgTDMuMDAwLDMuMDAwIi8+PC9zdmc+') no-repeat 50% 50% transparent;
                  background-size: 20px auto;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  width: 20px;
                  height: 20px;
                  margin: auto;

                  -webkit-transition: all 0.3s ease 0s;
                  -moz-transition: all 0.3s ease 0s;
                  -o-transition: all 0.3s ease 0s;
                  transition: all 0.3s ease 0s;
                }
                  .header-menu li.is-hovered > div > .js-menu-dropmarker:before {
                    -webkit-transform: rotate(-180deg);
                    -moz-transform: rotate(-180deg);
                    -ms-transform: rotate(-180deg);
                    -o-transform: rotate(-180deg);
                    transform: rotate(-180deg);
                  }

            .header-menu .dropdownmenu-container {
              display: none;
              position: relative;
              background: rgba(0,0,0,.15);
              border: none;
              padding: 0px;
              top: 0px !important;
              left: 0px !important;
              width: 100% !important;
              max-width: 100% !important;

              margin-top: 0px;
              visibility: visible;
              opacity: 1;
            }
              .header-menu .dropdownmenu-container > ul {
                display: flex;
                flex-flow: column nowrap;

                font-size: 0px;
                margin: 0px;
              }
                .header-menu .dropdownmenu-container > ul > li {
                  padding: 0px;
                  width: 100%;
                }

              .header-menu .dropdownmenu-container > .service-menu-item-discounts {
                padding: 0px 20px 20px;
              }


  .main-rotator-container {
    height: 400px;
  }
    .main-rotator-container.slick-dotted {
      height: 460px;
    }
      .main-rotator-slide-image {
        height: 400px;
      }

    .main-rotator-slide-code > div,
    #main-rotator-dots {
      width: 100%;
    }


  .breadcrumbs li:before,
  .breadcrumbs a,
  .breadcrumbs span {
    font-size: 12px;
  }


  .list-view-item {
    width: 33.3333%;
  }
    .list-view-item-image * {
      height: 0px !important;
      padding-top: 78%;
    }
    .portfolio-block-container .list-view-item-image * {
      padding-top: 80%;
    }
    .discount-block-container .list-view-item-image * {
      padding-top: 70%;
    }
      .header-menu .discount-block-container .list-view-item-image * {
        padding-top: 38%;
      }
      .header-menu .discount-block-container .list-view-item-name * {
        font-size: 20px;
      }

  .service-more-link,
  .portfolio-more-link,
  .price-table-more-link {
    text-align: center;
  }


  .advantages-block-container {
    background-size: cover;
  }


  .contact-map-container {
    height: 400px;
  }
}

@media all and (max-width: 799px) {
  .header-worktime {
    display: none;
  }
}

@media all and (max-width: 767px) {
  .st_dn { display: none; }
  .st_dn_i { display: none !important; }
  .st_db { display: block; }
  .st_dib { display: inline-block; }
  .st_dt { display: table; }
  .st_dtr { display: table-row; }
  .st_dtc { display: table-cell; }

  .st_w10p { width: 10%; }
  .st_w12p { width: 12.5%; }
  .st_w15p { width: 15%; }
  .st_w20p { width: 20%; }
  .st_w25p { width: 25%; }
  .st_w33p { width: 33.33%; }
  .st_w50p { width: 50%; }
  .st_w65p { width: 65%; }
  .st_w66p { width: 66.66%; }
  .st_w70p { width: 70%; }
  .st_w75p { width: 75%; }
  .st_wfull { width: 100%; }
  .st_wauto { width: auto; }

  .st_tal { text-align: left; }
  .st_tac { text-align: center; }
  .st_tar { text-align: right; }

  .st_fz8, .st_fz8_a, .st_fz8_a * { font-size: 8px; }
  .st_fz10, .st_fz10_a, .st_fz10_a * { font-size: 10px; }
  .st_fz11, .st_fz11_a, .st_fz11_a * { font-size: 11px; }
  .st_fz12, .st_fz12_a, .st_fz12_a * { font-size: 12px; }
  .st_fz14, .st_fz14_a, .st_fz14_a * { font-size: 14px; }
  .st_fz15 { font-size: 15px; }
  .st_fz16 { font-size: 16px; }
  .st_fz18 { font-size: 18px; }
  .st_fz20 { font-size: 20px; }
  .st_fz22 { font-size: 22px; }
  .st_fz24 { font-size: 24px; }
  .st_fz26 { font-size: 26px; }
  .st_fz28 { font-size: 28px; }
  .st_fz30 { font-size: 30px; }
  .st_fz32 { font-size: 32px; }
  .st_fz34 { font-size: 34px; }
  .st_fz36 { font-size: 36px; }
  .st_fz46 { font-size: 46px; }
  .st_fz48 { font-size: 48px; }
  .st_fz50 { font-size: 50px; }

  .st_fln { float: none; }
  .st_fll { float: left; }
  .st_flr { float: right; }


  .fancybox-slide > * {
    width: 100%;
  }


  .main-rotator-container {
    height: 340px;
  }
    .main-rotator-container.slick-dotted {
      height: 400px;
    }
      .main-rotator-slide-image {
        height: 340px;
      }

      .main-rotator-slide-title {
        font-size: 19px;
        width: 150px;
        min-width: 150px;
      }
      .main-rotator-slide-code > div > div:not(.main-rotator-slide-title) {
        font-size: 13px;
      }
      .main-rotator-slide-button .button-style > * {
        font-size: 16px;
      }


  .advantages-block-wrapper {
    margin-left: -35px;
  }
    .advantages-block-wrapper > div {
      width: 50%;
      padding-left: 35px;
    }


  .price-name,
  .price-price,
  .price-unit {
    font-size: 14px;
    padding: 2px 8px;
  }
    /*.price-name {
      padding-left: 15px;
    }*/
    .price-table-head .price-name,
    .price-table-head .price-price,
    .price-table-head .price-unit {
      font-size: 16px;
    }
    .price-table-subheader .price-name {
      font-size: 13px;
    }

  .portfolio-info-block-container {
    flex-wrap: wrap;
    align-content: flex-start;
  }
    .portfolio-info-block-container > div {
      width: 50%;
    }
    .portfolio-info-address-container { order: 1; }
    .portfolio-info-square-container { order: 4; }
    .portfolio-info-time-container { order: 3; }
    .portfolio-info-price-container { order: 2; }


  .footer-content-container {
    flex-direction: column;
    justify-content: flex-start;
    margin-right: -10px;
  }
    .footer-content-container > div {
      padding: 0px 10px 35px 50px;
    }
    .footer-contact-container {
      border-right: none;
      border-bottom: 1px dashed #fff;
      width: 100%;
      max-width: 100%;
    }

    .footer-callback-wrapper {
      width: 100% !important;
      max-width: 100% !important;
    }
      .footer-callback-wrapper .form-buttons {
        justify-content: flex-end !important;
      }
      .footer-callback-wrapper .form-row-name,
      .footer-callback-wrapper .form-row-phone {
        width: 50% !important;
      }


  .contact-map-container {
    height: 350px;
  }


  .footer-menu-container ul {
    justify-content: center;
  }


  .footer-copyrights > .wrapper {
    flex-direction: column;
  }
    .footer-copyright + * {
      margin-top: 15px;
    }
}

@media all and (max-width: 699px) {
  .m_dn { display: none; }
  .m_dn_i { display: none !important; }
  .m_db { display: block; }
  .m_dib { display: inline-block; }
  .m_dt { display: table; }
  .m_dtr { display: table-row; }
  .m_dtc { display: table-cell; }

  .m_w10p { width: 10%; }
  .m_w12p { width: 12.5%; }
  .m_w15p { width: 15%; }
  .m_w20p { width: 20%; }
  .m_w25p { width: 25%; }
  .m_w33p { width: 33.33%; }
  .m_w50p { width: 50%; }
  .m_w65p { width: 65%; }
  .m_w66p { width: 66.66%; }
  .m_w70p { width: 70%; }
  .m_w75p { width: 75%; }
  .m_wfull { width: 100%; }
  .m_wauto { width: auto; }

  .m_tal { text-align: left; }
  .m_tac { text-align: center; }
  .m_tar { text-align: right; }

  .m_fz8, .m_fz8_a, .m_fz8_a * { font-size: 8px; }
  .m_fz10, .m_fz10_a, .m_fz10_a * { font-size: 10px; }
  .m_fz11, .m_fz11_a, .m_fz11_a * { font-size: 11px; }
  .m_fz12, .m_fz12_a, .m_fz12_a * { font-size: 12px; }
  .m_fz14, .m_fz14_a, .m_fz14_a * { font-size: 14px; }
  .m_fz15 { font-size: 15px; }
  .m_fz16 { font-size: 16px; }
  .m_fz18 { font-size: 18px; }
  .m_fz20 { font-size: 20px; }
  .m_fz22 { font-size: 22px; }
  .m_fz24 { font-size: 24px; }
  .m_fz26 { font-size: 26px; }
  .m_fz28 { font-size: 28px; }
  .m_fz30 { font-size: 30px; }
  .m_fz32 { font-size: 32px; }
  .m_fz34 { font-size: 34px; }
  .m_fz36 { font-size: 36px; }
  .m_fz46 { font-size: 46px; }
  .m_fz48 { font-size: 48px; }
  .m_fz50 { font-size: 50px; }

  .m_fln { float: none; }
  .m_fll { float: left; }
  .m_flr { float: right; }



  .header-logo-container {
    display: none;
  }
  .header-contacts {
    width: 100%;
    justify-content: space-between;
  }
    .header-contacts > div + div {
      margin-left: 0px;
    }
    .header-phones {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      padding-left: 20px;
    }
      .header-phones div {
        margin: 0px 20px 4px 0px !important;
      }
        .header-phones a {
          font-size: 16px;
        }

    .header-callback .button-style > * {
      font-size: 14px;
      padding: .5em .7em .6em;
    }


  .main-rotator-container {
    height: 300px;
  }
    .main-rotator-container.slick-dotted {
      height: 360px;
    }
      .main-rotator-slide-image {
        height: 300px;
      }

      .main-rotator-slide-title {
        font-size: 19px;
        width: auto;
        min-width: 0px;
      }
      .main-rotator-slide-code > div > div:not(.main-rotator-slide-title):not(.main-rotator-slide-button) {
        display: none;
      }


  .list-view-item {
    width: 50%;
  }


  .discount-page-image {
    padding-top: 30%;
  }
}

@media all and (max-width: 479px) {
  .sm_dn { display: none; }
  .sm_dn_i { display: none !important; }
  .sm_db { display: block; }
  .sm_dib { display: inline-block; }
  .sm_dt { display: table; }
  .sm_dtr { display: table-row; }
  .sm_dtc { display: table-cell; }

  .sm_w10p { width: 10%; }
  .sm_w12p { width: 12.5%; }
  .sm_w15p { width: 15%; }
  .sm_w20p { width: 20%; }
  .sm_w25p { width: 25%; }
  .sm_w33p { width: 33.33%; }
  .sm_w50p { width: 50%; }
  .sm_w65p { width: 65%; }
  .sm_w66p { width: 66.66%; }
  .sm_w70p { width: 70%; }
  .sm_w75p { width: 75%; }
  .sm_wfull { width: 100%; }
  .sm_wauto { width: auto; }

  .sm_tal { text-align: left; }
  .sm_tac { text-align: center; }
  .sm_tar { text-align: right; }

  .sm_fz8, .sm_fz8_a, .sm_fz8_a * { font-size: 8px; }
  .sm_fz10, .sm_fz10_a, .sm_fz10_a * { font-size: 10px; }
  .sm_fz11, .sm_fz11_a, .sm_fz11_a * { font-size: 11px; }
  .sm_fz12, .sm_fz12_a, .sm_fz12_a * { font-size: 12px; }
  .sm_fz14, .sm_fz14_a, .sm_fz14_a * { font-size: 14px; }
  .sm_fz15 { font-size: 15px; }
  .sm_fz16 { font-size: 16px; }
  .sm_fz18 { font-size: 18px; }
  .sm_fz20 { font-size: 20px; }
  .sm_fz22 { font-size: 22px; }
  .sm_fz24 { font-size: 24px; }
  .sm_fz26 { font-size: 26px; }
  .sm_fz28 { font-size: 28px; }
  .sm_fz30 { font-size: 30px; }
  .sm_fz32 { font-size: 32px; }
  .sm_fz34 { font-size: 34px; }
  .sm_fz36 { font-size: 36px; }
  .sm_fz46 { font-size: 46px; }
  .sm_fz48 { font-size: 48px; }
  .sm_fz50 { font-size: 50px; }

  .sm_fln { float: none; }
  .sm_fll { float: left; }
  .sm_flr { float: right; }


  .header-phones {
    background-position: 0px 2px;
  }
    .header-phones a {
      font-size: 15px;
    }

  .header-callback .button-style > * {
    font-size: 12px;
  }


  .list-view-item,
  .discount-block-container .list-view-item,
  .header-menu .discount-block-container .list-view-item {
    width: 100%;
  }
  .portfolio-block-container .list-view-item {
    width: 50%;
  }


  .price-table-head .price-name,
  .price-table-head .price-price,
  .price-table-head .price-unit {
    font-size: 14px;
  }


  .portfolio-info-block-container > div {
    width: 100%;
  }


  .footer-callback-wrapper .form-row-name,
  .footer-callback-wrapper .form-row-phone {
    width: 100% !important;
  }


  .footer-copyright,
  .footer-copyright * {
    font-size: 13px;
  }
}